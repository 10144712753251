import { postRequest, getRequest, deleteRequest } from './index'

// 合作企业详情分页
export const agentSupplierList = (data, successCallback, failureCallback) => {
  postRequest('/agentSupplier/list', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取合作企业的详情
export const agentSupplierDetails = (id, successCallback, failureCallback) => {
  getRequest('/agentSupplier/details?id=' + id, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 合作企业详情添加和修改
export const agentSupplierAddAndUpdate = (data, successCallback, failureCallback) => {
  postRequest(data.id ? '/agentSupplier/update' : '/agentSupplier/add', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 合作企业删除
export const agentSupplierDelete = (id, successCallback, failureCallback) => {
  deleteRequest(`/agentSupplier/delete/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 合作企业详情删除
// export const agentSupplierDelete = (data, successCallback, failureCallback) => {
//   postRequest('/agent/supplier/delete', data, res => (
//     successCallback(res)
//   ), error => {
//     if (failureCallback)failureCallback(error)
//   })
// }

// 合作企业详情终止/合作
export const agentSupplierTerminationOrCooperation = (data, successCallback, failureCallback) => {
  postRequest('/agentSupplier/operateUpdate', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 合作企业详情修改
export const agentSupplierUpdate = (data, successCallback, failureCallback) => {
  postRequest('/agent/supplier/update', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 供应链客户分页
export const agentCustomerQueryByPage = (data, successCallback, failureCallback) => {
  postRequest('/agent/customer/queryByPage', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 供应链客户添加
export const agentCustomerAdd = (data, successCallback, failureCallback) => {
  postRequest('/agent/customer/add', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 供应链客户修改
export const agentCustomerUpdate = (data, successCallback, failureCallback) => {
  postRequest('/agent/customer/update', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 供应链客户详情
export const agentCustomerDetail = (data, successCallback, failureCallback) => {
  postRequest('/agent/customer/detail', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 供应链客户终止/启用
export const agentCustomerEnableOrDisanle = (data, successCallback, failureCallback) => {
  postRequest('/agent/customer/enableOrDisanle', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 供应链客户下拉列表(客户状态仅为[1正常]的数据)
export const agentCustomerQueryByList2AccessStateIs1 = (data, successCallback, failureCallback) => {
  getRequest('/agent/customer/queryByList2AccessStateIs1', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 供应链客户下拉列表(客户状态为所有[0终止，1正常]的数据)
export const agentCustomerQueryByList2AllAccessState = (data, successCallback, failureCallback) => {
  getRequest('/agent/customer/queryByList2AllAccessState', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 授信管理
// 授信管理分页  1-经销商端，2-垫资端，3-运营端
export const agentCreditInfoList = (data, successCallback, failureCallback) => {
  postRequest('/agentCreditInfo/listByPage', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取经销商端的详情
export const agentFinancialInfoDetails = (id, successCallback, failureCallback) => {
  getRequest('/agentCreditInfo/details?operFlat=2&id=' + id, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取代采端的详情
export const agentCreditInfoDetails = (id, successCallback, failureCallback) => {
  getRequest('/agentCreditInfo/details?id=' + id, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 模糊搜索经销商
export const agentFinancialInfoSearch = (data, successCallback, failureCallback) => {
  postRequest('/agentFinancialInfo/search', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 企业财务信息管理
export const agentFinancialInfoList = (data, successCallback, failureCallback) => {
  postRequest('/agentFinancialInfo/list', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 添加存量客户
export const agentCreditInfoInsertStockData = (data, successCallback, failureCallback) => {
  postRequest('/agentCreditInfo/add', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 添加审核包括风控和业务员
export const agentCreditInfoOperateUpdate = (data, successCallback, failureCallback) => {
  postRequest(data.currentStatus === 1 ? '/agentCreditInfo/busiAudit' : '/agentCreditInfo/riskAudit', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 额度调整
export const agentCreditInfoAdjustmentQuota = (data, successCallback, failureCallback) => {
  postRequest('/agentCreditInfo/adjustmentQuota', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 额度授信签署（只传授信额度对象id
export const agentCreditInfoCustomersSign = (id, successCallback, failureCallback) => {
  getRequest('/agentCreditInfo/getEsignUrl?creditId=' + id, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 额度授信签署 用印申请
export const agentCreditInfoSealApply = (data, successCallback, failureCallback) => {
  getRequest(`/agentCreditInfo/sealApply?creditId=${data.id}&oaType=${data.oaType}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 额度授信签署 拒绝签署
export const agentCreditInfoRefusedSign = (data, successCallback, failureCallback) => {
  postRequest('/agentCreditInfo/refusedSign', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 获取合作企业
export const agentSupplierCmpList = (partnerName, successCallback, failureCallback) => {
  postRequest('/agentSupplierCmp/list?partnerName=' + partnerName, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
